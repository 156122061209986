<template>
	<div class="contact-card">
	    <div class="cs-image-container cs-image-ratio-1x1">
            <img :src="data.image" :alt="data.title">
        </div>
        <h4>{{data.title}}</h4>
        <span v-html="data.text"></span>
        <div class="btns">
            <Buttons :data="buttons" />
        </div>
	</div>
</template>

<script>
export default {
    name: "CSUtilsContactCards",
    components: {
        Buttons: () => import("./CSUtilsButtons.vue"),
    },
    props: {
        data: Object
    },
    computed: {
        buttons() {
            let buttons = []
            for (var i = 0; i < this.data.links.length; i++) {
            	buttons.push({
            		buttonText: this.data.links[i].label,
            		url: this.data.links[i].url
            	})
            }
            return buttons
        }
    },
    mounted(){}
};
</script>

<style lang="scss" scoped>
    .contact-card {
        background: #ebf1f9;
        height: calc(100% - 15px);
        margin-bottom: 15px;
        border-radius: 15px;
        border: 1px solid #ddd;
        overflow: hidden;
        padding: 15px;
        -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);

        &>div:first-child {
            margin: -15px -15px 15px -15px;
        }
        .btns{
            text-align:center;
        }
    }
</style>
